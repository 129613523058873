html,
body {
    padding: 0px;
    margin: 0px;
}

html {
    height: 100%;
    overflow: hidden;
    position: relative;
}

body {
    height: 100%;
    overflow: hidden;
    position: relative;
}

header {
    padding-top: 30px;
    padding-left: 60px;
    padding-right: 60px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 9999;
    position: fixed;
    top: 0px;
    left: 0px;
    width: calc(100% - 120px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#dark-mode-toggle {
    cursor: pointer;
    box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, .3);
    background: white;
    z-index: 999;
    padding: 6px;
    border-radius: 15px;
    color: black !important;
}

.sb-blocks-fitness__sections-container {
    position: relative;
    z-index: 2;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.sb-blocks-fitness__instance-container {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    height: 100%;
    width: 100%;
    z-index: 0;
}

.sb-blocks-fitness__section--debug {
    min-height: calc(100% - 120px);
    padding: 60px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-right: auto;
    width: 50%;
}

#sb-site-logo {
    padding: 0px;
    margin: 0px;
}

#sb-site-logo img {
    width: 120px;
    filter: drop-shadow(3px 3px 3px black);
}

body:not(.sb-dark-mode) #sb-site-logo img {
    filter: invert(1) !important;
}

#test-section1 h1 {
    color: #5376B8;
}

h1 {
    font-size: 90px;
    margin: 0px;
}

h2 {
    margin: 0px;
    font-size: 60px;
}

#test-section2 {
    text-align: left;
}

.sb-dark-mode * {
    color: white !important;
}

#kontakt-button {
    margin-top: 30px;
    background: #5376B8;
    font-size: 30px;
    min-width: 33%;
    text-decoration: none;
    color: white;
    padding: 15px;
    text-align: center;
    box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, .3);
}

#kontakt-button:hover {
    background: white;
    color: #5376B8 !important;
}

@media (max-width: 768px) {
    header {
        padding-left: 12px;
        padding-right: 12px;
        width: calc(100% - 24px);
    }
    .sb-blocks-fitness__section--debug {
        padding: 12px;
        height: calc(100% - 24px);
        width: calc(100% - 24px);
    }
    h1 {
        font-size: 42px;
    }
    h2 {
        font-size: 30px;
    }
    #kontakt-button {
        font-size: 20px;
    }
}